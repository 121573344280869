exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-auriculotherapie-js": () => import("./../../../src/pages/auriculotherapie.js" /* webpackChunkName: "component---src-pages-auriculotherapie-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-infospratiques-js": () => import("./../../../src/pages/infospratiques.js" /* webpackChunkName: "component---src-pages-infospratiques-js" */),
  "component---src-pages-mentionslegales-js": () => import("./../../../src/pages/mentionslegales.js" /* webpackChunkName: "component---src-pages-mentionslegales-js" */),
  "component---src-pages-mtc-js": () => import("./../../../src/pages/mtc.js" /* webpackChunkName: "component---src-pages-mtc-js" */),
  "component---src-pages-qigong-js": () => import("./../../../src/pages/qigong.js" /* webpackChunkName: "component---src-pages-qigong-js" */),
  "component---src-pages-seance-js": () => import("./../../../src/pages/seance.js" /* webpackChunkName: "component---src-pages-seance-js" */),
  "component---src-pages-temoignages-js": () => import("./../../../src/pages/temoignages.js" /* webpackChunkName: "component---src-pages-temoignages-js" */),
  "component---src-pages-ventousotherapie-js": () => import("./../../../src/pages/ventousotherapie.js" /* webpackChunkName: "component---src-pages-ventousotherapie-js" */)
}

